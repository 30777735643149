@import "../../_colors.scss";

.link-underline {
		border-bottom-width: 0;
		background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
		background-size: 0 3px;
		background-position: 0 100%;
		background-repeat: no-repeat;
		transition: background-size .5s ease-in-out;
	}

	.link-underline-black {
		background-image: linear-gradient(transparent, transparent), linear-gradient($blue, $blue)
	}

	.link-underline:hover {
		background-size: 100% 3px;
		background-position: 0 100%
	}


	.transition-menu {
		transition: height 900ms ease-in-out;
	}

	.transition-link {
		transition: transform 400ms ease-in-out;
	}

	.transition-link-text {
		transition: opacity 300ms ease-in-out;
		transition-delay: 300ms;
	}
	
	.open-menu {
		height: 100%;
	}
	
	.close-menu {
		height: 0%;
	}

	.reveal-menu-links {
		transform: translateX(0);
	}

	.reveal-link-text {
		opacity: 1;
	}
	