@import "../../_colors";

.clipper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100% , 100% 0%);   
}

.transition {
    transition: clip-path 400ms ease-in-out, background-color 400ms ease-in-out;
}

.clipping {
    clip-path: polygon(0% 0%, 0% 100%, 0% 100% , 100% 0%);   
}

.award-parent-umich {
    filter: drop-shadow(.5em .55em 0px $maize);
}

.award-parent-umd {
    filter: drop-shadow(.5em .55em 0px $black);
}

.umich {
    background-color: $blue;
}

.umd {
    background-color: $terp-red;
}