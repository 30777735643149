.wrapper{
    position:relative;
     z-index:1;
 }
 
 .wrapper::before{
     content:'';
     position:absolute;
     background: linear-gradient(0deg, #00000038 30%, #ffffff44 100%);
     width:100%;
     height:100%;
     z-index:-1;
     left: 0;
     top: 0;
 }