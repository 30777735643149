.shadow-stripe {
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.grid-cols-15 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
}

.grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
}

.shad {
    clip-path: inset(-5px -1px -5px -5px);

}

.transition-season {
    transition: all 700ms ease-in-out;
}

.transition-desc {
    transition: all 700ms ease-in-out;
    transition-delay: 1s;
}

.fade-season {
    opacity: 1;
}

.fade-in-fall {
    opacity: 1;
    transform: translateX(2em);
}

.fade-in-winter {
    opacity: 1;
    transform: translateX(-2em);
}