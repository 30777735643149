div > svg {
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
}

/* .desc-enter {
    animation: wipe-enter 1s 1;
}

.fade-in {
    opacity: 1;
}

@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
} */