@import "../../_colors.scss";

.transition-p {
    transition: all 400ms ease-in-out;
    transition-delay: 750ms;
}

.in {
    /* transform: translateX(-50px); */
    opacity: 1;
}

.fade-in {
    opacity: 1;
}

.flip {
    background-color: white;
    color: black;
}

.desc-enter {
    animation: wipe-enter 1s 1;
}

@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}

#nzDesc {
    opacity: 0;
    transform: scale(1.2);
    transition: opacity 1.5s ease, transform 1.5s ease;
}

.nz-desc-transition {
    opacity: 1 !important;
    transform: none !important;
}




  svg circle {
    fill: $nz-red;
  }
  
  @keyframes from0to360 {
    from{transform:rotate(0);}
    to{transform:rotate(360deg);}
  }
  
  #Circle1 {
    animation: from0to360 1s linear infinite;
    cx: 100;
    cy: 100;
    r: 100;
    transform-origin: 100px 100px;
  }
  
  @keyframes from360to0 {
    from{transform:rotate(360deg);}
    to{transform:rotate(0deg);}
  }
  
  #Circle2 {
    animation: from360to0 2s linear infinite;
    cx: 100;
    cy: 100;
    r: 100;
    transform-origin: 100px 100px;
  }
  
  #Circle3 {
    animation: from0to360 3s linear infinite;
    cx: 100;
    cy: 100;
    r: 100;
    transform-origin: 100px 100px;
  }
  
  #Circle4 {
    animation: from360to0 2.5s linear infinite;
    cx: 100;
    cy: 100;
    r: 100;
    transform-origin: 100px 100px;
  }

  svg#nzsvg > g {
      fill: white;
      stroke: black;
      stroke-width: 1px;

        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .3));
  }

  .nz-in {
    animation: dash 5s linear forwards;
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }